<template>
  <main class="main">
    <div class="nav">
      <h2>
        <span class="back" v-on:click="backToHome()">&lt;&nbsp;&nbsp;返回</span
        >测算记录
      </h2>
    </div>
    <div class="order">
      <div class="container">
        <div class="row orders">
          <div v-if="paidHistory.length === 0" class="col-md-6 emptyOrder">
            <div class="section-card">
              <h3 class="title" name="orderName">目前没有任何测算记录</h3>
              <hr class="separate" />
              <a
                class="btn btn-lg btn-result"
                name="orderResult"
                v-on:click="backToHome()"
                >前往测算！</a
              >
            </div>
          </div>

          <div
            v-for="(item, index) in paidHistory"
            :key="index"
            class="col-md-6 orderItem"
          >
            <div class="section-card">
              <h3 class="title" name="orderName">真爱紫微合盘</h3>
              <hr class="separate" />
              <div class="item">
                <span class="subtitle">订单编号</span>
                <span class="content">{{ item.orderNo }}</span>
              </div>
              <div class="item">
                <span class="subtitle">建立时间</span>
                <span class="content">{{
                  item.quizTime
                    | moment('timezone', 'Etc/GMT-8', 'YYYY-MM-DD HH:mm:ss')
                }}</span>
              </div>
              <div class="item">
                <span class="subtitle">测算姓名</span>
                <span class="content"
                  >{{ item.name }} / {{ item.gender == 1 ? '男' : '女' }}</span
                >
              </div>
              <div class="item">
                <span class="subtitle">阳历生日</span>
                <span class="content"
                  >{{ item.birthdayYear }} 年 {{ item.birthdayMonth }} 月
                  {{ item.birthdayDate }} 日
                  {{ getLunarHour(item.birthdayHour) }} 时</span
                >
              </div>
              <div class="item">
                <span class="subtitle">对方姓名</span>
                <span class="content"
                  >{{ item.targetName }} /
                  {{ item.targetGender == 1 ? '男' : '女' }}</span
                >
              </div>
              <div class="item">
                <span class="subtitle">阳历生日</span>
                <span class="content"
                  >{{ item.targetBirthdayYear }} 年
                  {{ item.targetBirthdayMonth }} 月
                  {{ item.targetBirthdayDate }} 日</span
                >
              </div>

              <div
                href="/orders/history/<%= order.id %>"
                class="btn btn-lg btn-result false"
                name="orderResult"
                @click="linkToResult(item.orderNo)"
              >
                {{ item.paid == 1 ? '查看结果' : '付款后自动开通' }}
              </div>
              <p style="color: 4a4a4a;">
                {{ item.paid == 1 ? ' ' : '若付款资讯遗失请重新下单' }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from 'moment-timezone'

import { mapState, mapGetters } from 'vuex'
import { SHI_CHEN } from '@/constants/shi-chen-list'
import { FORTUNE_GETTERS } from '@/store/modules/fortune-getters-types'

export default {
  name: 'History',
  mounted: function() {
    try {
      TtxTrack.track('he-pan', 'FORTUNE_HISTORY')
    } catch (e) {
      // console.log(e)
    }
  },
  methods: {
    backToHome: function() {
      this.$router.push({ name: 'Home' })
    },
    linkToService: function() {
      this.$router.push({ name: 'Service' })
    },
    linkToResult: function(orderNo) {
      this.$router.push({ name: 'Result', params: { orderNo } })
    },
    getLunarHour: function(hour) {
      if (hour !== 0 && hour % 2 === 0) {
        hour--
      }
      return SHI_CHEN[hour]
    }
  },
  computed: {
    ...mapState({
      history: state => state.fortune.history
    }),
    ...mapGetters('fortune', {
      isNotEmpty: FORTUNE_GETTERS.IS_NOT_EMPTY,
      isLoading: FORTUNE_GETTERS.IS_LOADING
    }),
    paidHistory: function() {
      return this.history.filter(function(item) {
        return item.paid === true
      })
    }
  }
}
</script>

<style src="@/assets/css/order.css"></style>
<style>
.nav {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.back {
  position: absolute;
  left: 3%;
}
</style>
